import { InfoBox } from 'components/InfoBox';

import { FormContextConsumer } from './FormContext';

type Props = {
  match?: string;
  ignore?: Array<string | null | undefined>;
};

export function GeneralError({ match, ignore }: Props) {
  return (
    <FormContextConsumer>
      {({ errors }) => {
        const error = errors[''] || errors.general;

        if (
          !error ||
          (match && error !== match) ||
          (ignore && ignore.includes(error))
        ) {
          return null;
        }

        return (
          // className used to scroll to error
          <InfoBox
            className="error general-error"
            $type="danger"
            $marginBottom={4}
          >
            {error}
          </InfoBox>
        );
      }}
    </FormContextConsumer>
  );
}
