import { Box } from 'components/Box';
import { Divider } from 'components/Divider';
import { Form, FormUsageContext } from 'components/Form/Form';
import { FormSubmitFunction } from 'components/Form/FormContext';
import { GeneralError } from 'components/Form/GeneralError';
import { InputField } from 'components/Form/InputField';
import { SubmitButton } from 'components/Form/SubmitButton/SubmitButton';
import { InfoBox } from 'components/InfoBox';
import { AppleOAuthButton } from 'components/OAuthButton/AppleOAuthButton';
import { FacebookOAuthButton } from 'components/OAuthButton/FacebookOAuthButton';
import { GoogleOAuthButton } from 'components/OAuthButton/GoogleOAuthButton';
import { BodySmall } from 'components/Text/BodySmall';

import { LoginFormForgotPasswordLink } from './LoginFormForgotPasswordLink';
import { LoginFormSignupLink } from './LoginFormSignupLink';

export type LoginFormValues = {
  email: string;
  password: string;
};

type Props = {
  analyticsTitle: string;
  usageContext: FormUsageContext;
  initialValues: LoginFormValues;
  thirdPartyLoginError: string | null | undefined;
  showSignupModal: (email: string) => void;
  getSignupUrl: (email: string) => {
    pathname: string;
    state: Record<string, unknown>;
  };
  signUpOverrideFn?: () => void;
  showForgotPasswordModal: (data: { email: string }) => void;
  getForgotPasswordUrl: (data: { email: string }) => {
    pathname: string;
    state: Record<string, unknown>;
  };
  getThirdPartyLoginUrl: (
    provider: 'facebook' | 'google' | 'apple',
  ) => Promise<string> | string;
  submitLogin: FormSubmitFunction<LoginFormValues>;
  centered?: boolean;
  initialWarning?: string | null | undefined;
  compactView?: boolean;
};

export function LoginForm({
  analyticsTitle,
  usageContext,
  initialValues,
  thirdPartyLoginError,
  showSignupModal,
  getSignupUrl,
  signUpOverrideFn,
  showForgotPasswordModal,
  getForgotPasswordUrl,
  getThirdPartyLoginUrl,
  submitLogin,
  centered,
  initialWarning,
  compactView,
}: Props) {
  return (
    <Form
      analyticsTitle={analyticsTitle}
      usageContext={usageContext}
      data-qa-id="login-form"
      initialValues={initialValues}
      onSubmit={submitLogin}
      noScrollToError
      render={({ values: { email } }) => (
        <Box textAlign={centered ? 'center' : 'left'}>
          {initialWarning && (
            <InfoBox $type="warning" $marginTop={20} $marginBottom={20}>
              {initialWarning}
            </InfoBox>
          )}
          {thirdPartyLoginError && (
            <InfoBox className="error" $type="danger" $marginBottom={4}>
              {thirdPartyLoginError}
            </InfoBox>
          )}

          <Box mb={[16, null, 16]}>
            <BodySmall textAlign="center">
              {getText("Don't have an account?")}{' '}
              <LoginFormSignupLink
                email={email}
                getSignupUrl={getSignupUrl}
                signUpOverrideFn={signUpOverrideFn}
                showSignupModal={showSignupModal}
                formUsageContext={usageContext}
              >
                {getText('Sign Up')}
              </LoginFormSignupLink>
            </BodySmall>
          </Box>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
            }}
          >
            <GoogleOAuthButton getUrl={() => getThirdPartyLoginUrl('google')} />
            <FacebookOAuthButton
              getUrl={() => getThirdPartyLoginUrl('facebook')}
            />
            <AppleOAuthButton getUrl={() => getThirdPartyLoginUrl('apple')} />
          </div>

          {!compactView && <Divider marginTop={24} marginBottom={24} />}

          <GeneralError />

          <InputField
            name="email"
            type="email"
            dataModalFocus={usageContext === 'modal' ? 'true' : undefined}
            placeholder={getText('casey.smith@example.com')}
            label={
              /* TRANSLATORS: Login form */
              getText('Email')
            }
          />

          <InputField
            name="password"
            type="password"
            label={
              /* TRANSLATORS: Login form */
              getText('Password')
            }
          />

          <Box mt={[16, null, 40]} display="flex" justifyContent="flex-end">
            <SubmitButton fullWidth size="large">
              {
                /* TRANSLATORS: log in button */
                getText('Log In')
              }
            </SubmitButton>
          </Box>

          <Box mt={[16, null, 32]}>
            <BodySmall textAlign="center">
              <Box mt="8px">
                <LoginFormForgotPasswordLink
                  email={email}
                  formUsageContext={usageContext}
                  getForgotPasswordUrl={getForgotPasswordUrl}
                  showForgotPasswordModal={showForgotPasswordModal}
                >
                  {getText('I forgot my password')}
                </LoginFormForgotPasswordLink>
              </Box>
            </BodySmall>
          </Box>
        </Box>
      )}
    />
  );
}
