import interpolateComponents from '@automattic/interpolate-components';
import { useEffect, useState } from 'react';
import { sprintf } from 'sprintf-js';

import { Box } from 'components/Box';
import { Button } from 'components/Button/Button';
import { EmailSignupFormContainer } from 'containers/EmailSignupFormContainer';
import { LoginFormContainer } from 'containers/LoginFormContainer';
import { CmsApiSignUpBlockData } from 'modules/cms/api/types/blocks/CmsApiSignUpBlock';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { gutterWidth, maxWidth } from 'theme/theme';
import { trackEvent } from 'utils/analytics/track/trackEvent';

import { CmsSignUpBlockHolder } from './CmsSignUpBlock.styled';
import { CmsSignUpBlockHeadline } from './CmsSignUpBlockHeadline';

type Props = {
  data: CmsApiSignUpBlockData;
  isPageHeader: boolean;
};

export function CmsSignUpBlock({
  data: {
    headline,
    redirectUrl,
    bgImage,
    loggedInHeadline,
    loggedInRedirectButtonText,
  },
  isPageHeader,
}: Props) {
  const { user } = useUserEnvironment();

  const [emailInUse, setEmailInUse] = useState('');
  useEffect(() => {
    trackEvent('View Sign Up Form');
  }, []);

  const useLoggedInUi = user && loggedInHeadline && !emailInUse;

  return (
    <div data-qa-id="sign-up">
      <CmsSignUpBlockHolder
        $imageHandle={bgImage?.handle}
        $mimetype={bgImage?.mimetype}
      >
        <Box
          mx="auto"
          pt={['58px', null, gutterWidth / 2, '52px']}
          pb={['58px', null, gutterWidth / 2, '96px']}
          px={['24px', null, gutterWidth / 2, gutterWidth]}
          maxWidth={maxWidth[3]}
          width={1}
        >
          <Box display="flex" justifyContent="flex-end">
            <Box
              width={[1, null, 0.5]}
              py={[gutterWidth / 2, null, null, '32px']}
              px={[gutterWidth / 2, null, null, gutterWidth]}
              borderRadius={[8, 0, 12]}
              bg="white"
              boxShadow="0 0 6px rgba(0,0,0,0.3)"
            >
              <CmsSignUpBlockHeadline
                text={useLoggedInUi ? loggedInHeadline : headline}
                isPageHeader={isPageHeader}
              />

              <Box mt="40px">
                {useLoggedInUi && (
                  <>
                    {interpolateComponents({
                      mixedString: sprintf(
                        getText(
                          'You are logged in as {{strong}}%(email)s{{/strong}}',
                        ),
                        { email: user.email },
                      ),
                      components: { strong: <strong /> },
                    })}

                    {redirectUrl && loggedInRedirectButtonText && (
                      <Box mt={24}>
                        <Button
                          type="link"
                          href={redirectUrl}
                          variant="primary"
                        >
                          {loggedInRedirectButtonText}
                        </Button>
                      </Box>
                    )}
                  </>
                )}

                {!useLoggedInUi && (
                  <>
                    {!emailInUse && (
                      <EmailSignupFormContainer
                        analyticsTitle="[cms] Sign Up block - email available form"
                        usageContext="page"
                        subscribedSubsite={null}
                        redirectUrl={redirectUrl}
                        compactView
                        hideLoginBlock
                        customButtonText={getText('Continue')}
                        setEmailInUse={setEmailInUse}
                      />
                    )}

                    {emailInUse && (
                      <LoginFormContainer
                        analyticsTitle="[cms] Sign Up block - email in use"
                        usageContext="page"
                        redirectUrl={redirectUrl}
                        email={emailInUse}
                        onLogin={null}
                        compactView
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </CmsSignUpBlockHolder>
    </div>
  );
}
